import { getAllDistributionFee } from '@deorderbook/sdk'
import { defineStore } from 'pinia'
import { type DistributionFeeInfo } from '@base/types/distributionFee'

const DEFAULT_DISTRIBUTION_FEES = {
  entryFee: '0',
  withdrawFee: '0',
  exerciseFee: '0',
  redeemFee: '0',
  bulletToReward: '0',
} as DistributionFeeInfo

export const useDistributionFeeStore = defineStore('distributionFee', () => {
  const {
    fetchState: fetchDistributionFeesState,
    refreshAllState: refreshDistributionFeesAllState,
    getCaches: getDistributionFeesCaches,
  } = useAsyncCache(() => {
    return getAllDistributionFee()
  }, DEFAULT_DISTRIBUTION_FEES)

  return {
    fetchDistributionFeesState,
    refreshDistributionFeesAllState,
    getDistributionFeesCaches,
  }
})
